import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../utils/AppSlice';
import Socials from './Socials';
import SelectLang from './SelectLang';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { LANG } from '../utils/langConstant';
import { AUTHOR_NAME, AUTHOR_NICKNAME, AUTHOR_SURNAME } from '../constants';

const Sidemenu = () => {
  const lang = useSelector((state) => state.app.lang);
  const data = LANG[lang] || LANG['sk'];
  const isMenuOpen = useSelector((store) => store.app.isMenuOpen);
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false); // Controls DOM visibility
  const [isAnimating, setIsAnimating] = useState(false); // Tracks animation state
  const menuRef = useRef(null);

  // Handle animation and visibility state
  useEffect(() => {
    if (isMenuOpen) {
      setVisible(true); // Ensure menu is in the DOM
      setTimeout(() => setIsAnimating(true), 50); // Trigger open animation
    } else {
      setIsAnimating(false); // Start closing animation
      const timeout = setTimeout(() => setVisible(false), 500); // Remove from DOM after animation
      return () => clearTimeout(timeout); // Cleanup timeout
    }
  }, [isMenuOpen]);

  // Close menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        dispatch(toggleMenu());
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen, dispatch]);

  const toggleMenuHandler = () => {
    dispatch(toggleMenu());
  };

  return (
    // Conditionally render menu based on `visible` state
    <div
      ref={menuRef}
      className={`secondary-color h-full w-3/5 overflow-hidden fixed top-0 z-50 p-10 border-r-2 border-[#2d995b]
      transition-transform duration-500 ease-in-out ${
        isAnimating ? 'translate-x-0' : '-translate-x-full'
      }`}
      style={{ display: visible ? 'block' : 'none' }} // Show menu only when visible
    >
      <div className="flex justify-end">
        <SelectLang />
        <button
          className="text-4xl text-red-900 hover:text-red-700 hover:animate-spin"
          aria-label="close menu button"
          onClick={toggleMenuHandler}
        >
          <IoIosCloseCircleOutline />
        </button>
      </div>
      <ul className="text-white text-2xl mt-10">
        <li className="py-2 hover:text-[#2d995b]">
          <a href="#Home">{data.menu.text1}</a>
        </li>
        <li className="py-2 hover:text-[#2d995b]">
          <a href="#Projekty">{data.menu.text2}</a>
        </li>
        <li className="py-2 hover:text-[#2d995b]">
          <a href="#Referencie">{data.menu.text3}</a>
        </li>
        <li className="py-2 hover:text-[#2d995b]">
          <a href="#Galeria">{data.menu.text4}</a>
        </li>
      </ul>
      <div className="absolute bottom-3 right-7 sm:right-0 pr-0 sm:pr-14 text-slate-500 fonts">
        <span>{AUTHOR_NAME}</span>
        <span className="text-[#2d995b] px-2">{AUTHOR_NICKNAME}</span>
        <span>{AUTHOR_SURNAME}</span>
      </div>
      <span className="absolute bottom-7 right-2 sm:right-0 pr-0 sm:pr-9">
        <Socials />
      </span>
    </div>
  );
};

export default Sidemenu;

